import { apiFunction } from "../apiCall/function"

export const EnterOtp = async (data) => {
  return await apiFunction("auth/sendOtp", "post", data, false, 'body')
}

export const LoginData = async (data) => {
  return await apiFunction("auth/login", "post", data, false, 'body')
}

export const RegisterData = async (data) => {
  return await apiFunction("auth/register", "post", data, false, 'body')
}