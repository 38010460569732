import React from 'react'
import './homepage.css'
import headerImage from '../img/online_admision.png'
import FAQ from '../download/faq.pdf'
import exam from '../assests/pdf/Govt order for Bipard exam.pdf'
import syllabus from '../assests/pdf/Syllabus for Computer Competency Test Examination.pdf'
import p1 from '../assests/image/p1.png'
import p2 from '../assests/image/p2.png'
import p3 from '../assests/image/p3.png'
import p4 from '../assests/image/p4.png'

const HomePage = () => {
  return (
    <section>
      <div className='container-fluid homepage_layout'>
        <div className='right_section'>
          {/* <img className='image_department' src={headerImage} alt='departmental examination portal' /> */}
          <h3 style={{ textAlign: 'center', color: 'white', }}>Computer Competency Test </h3>
          <h3 style={{ textAlign: 'center', color: 'cyan', }}>Examination</h3>
          <h3 style={{ textAlign: 'center', color: 'white', }}>Portal</h3>
          <h3 style={{ textAlign: 'center', color: 'white', marginBottom: '50px' }}>For All Government Employees</h3>
          <div className='button_links' >
            <div className='icon_button' title='Question Bank'>
              {/* <a href="/subject-page" target='_blank' > */}
              <a href="/questions" target='_blank' >
                <img className='icon-home' src={p1} alt='question_bank_icon' />
                <span>Question Bank</span>
              </a>
            </div>
            <div className='icon_button' title='Exam Syllabus' >
              <a href='#' data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img className='icon-home' src={p2}alt='question_bank_icon' />
                <span>Exam Syllabus</span>
              </a>
            </div>
            {/* <div className='icon_button' title='Feedback'>
              <a href="#">
                <img src='https://borexam.bihar.gov.in/upload/image/feedback_icon.png' alt='question_bank_icon' />
                <span>Feedback</span>
              </a>
            </div> */}
            {/* <div className='icon_button' title='FAQ'>
              <a href={FAQ} target='_blank' rel="noreferrer">
                <img src='https://borexam.bihar.gov.in/upload/image/faq_icon.png' alt='question_bank_icon' />
                <span>&nbsp;&nbsp;&nbsp; FAQ</span>
              </a>
            </div> */}
            <div className='icon_button' title='Practice Test'>
              <a href="http://206.189.143.221:7073/language" rel="noopener noreferrer" target='_blank'>
                <img className='icon-home' src={p3} alt='question_bank_icon' />
                <span>Practice Test</span>
              </a>
            </div>
            <div className='icon_button' title='Verification'>
              <a href="/certificate-check" target='_blank'>
                <img className='icon-home' src={p4} alt='question_bank_icon' />
                <span>Verification</span>
              </a>
            </div>

          </div>


        </div>
        <div className='left_section'>
          <div className="col-sm-12 col-xs-6" >
            <div className="scrollbox" tabIndex="0">
              <div className="scrollbox-content">
                <h3>।। महानिदेशक का संदेश ||</h3>
                <p>
                  मुझे यह सूचित करते हुए अपार हर्ष हो रहा है कि बिहार लोक प्रशासन एवं ग्रामीण विकास संस्थान (बिपार्ड) द्वारा अब कम्प्यूटर सक्षमता जाँच परीक्षा प्रारम्भ की जा रही है। पहले यह परीक्षा NIELIT नाम की संस्था लेती थी। अब बिहार सरकार द्वारा विभागीय नियमों में बदलाव करते हुए बिहार सरकारी सेवक (कम्प्यूटर सक्षमता जाँच परीक्षा) नियमावली 2022 गठित की गयी है। यह नियमावली <a href={exam} target={"_blank"} style={{ cursor: "pointer", textDecoration: "underline" }}> इस लिक </a>
                  पर देखी जा सकती है।
                </p>
                <p>
                  यह परीक्षा वैसे सभी कर्मियों को देनी होगी जिनकी सेवा संवर्ग नियमावली में कम्प्यूटर परीक्षा की

                  उत्तीर्णता की बाध्यता है।
                </p>
                <p>
                  यह परीक्षा बिपार्ड के पटना एवं गया परिसर में ली जायेगी। साथ ही यह परीक्षा पूरे वर्ष दी जा

                  सकती है। यानि कि इच्छुक सरकारी कर्मी अपनी सुविधानुसार स्वयं तय कर सकते हैं कि वह किस तिथि को यह परीक्षा देना चाहेंगे।
                </p>
                <p>

                  कर्मीगण हमारी वेबसाइट पर परीक्षा हेतु पंजीकरण करते हुए अपनी परीक्षा की तिथि, समय तथा

                  स्थान का चयन स्वयं कर सकते हैं। इच्छुक परीक्षार्थियों के सहायता के लिए एक Question Bank वेबसाइट पर उपलब्ध है, जिस पर आप परीक्षा से कुछ दिनों पहले अभ्यास कर सकते हैं।
                </p>
                <p>

                  मेरा यह भी अनुरोध होगा कि परीक्षा देने के उपरांत अपना फीडबैक बिपार्ड को उपलब्ध करायें ताकि

                  बिपार्ड इस परीक्षा को और भी सुव्यवस्थित कर सके और बेहतर गुणवत्ता प्रदान कर सके।
                </p>

                <p>  शुभकामनाओं सहित।</p>
                <div className="pull-right"><strong>( केके पाठक  )</strong><br />महानिदेशक बिपार्ड,
                  <br /> बिहार</div><p></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal */}
      <div class="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Gazette List</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <table class="table table-bordered border-primary">
                <thead style={{ backgroundColor: '#496CAD', color: 'white' }}>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope="col">Gazette Name</th>
                    <th scope="col">Published Date</th>
                    <th scope="col">PDF</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Computer competency test examination syllabus</td>
                    <td>17/12/2022</td>
                    <td><a href={syllabus} target="_blank"><i className='fa fa-download'></i></a> </td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </section >
  )
}

export default HomePage