import React from 'react'

const PrivacyPolicy = () => {
  return (
    <>
      <div className='container'>
        <div className='refund-policy'>
          <h1>Privacy Policy </h1>
          <p>
            Bihar Institute of Public Administration & Rural Development, Walmi Campus,      Phulwarisharif,Patna 801505which owns, operates and manages the web based
            platform https://bipardexam.thecodebucket.com/ (the “Platform”) is committed to protecting Your privacy and the information that You share while using the Platform. We value the trust You place in Us. That’s why, We maintain reasonable security standards for securing Your information.
          </p>
          <p>
            This privacy policy (“Privacy Policy”) specifies the manner in which personal data and other information is collected, received, stored, processed, disclosed, transferred, dealt with, or otherwise handled by Us. This Privacy Policy does not apply to information that You provide to, or that is collected by, any third-party through the Platform, and any Third-Party Sites that You access or use in connection with the Platform Services.
          </p>
          <p>
            Please read the Privacy Policy carefully prior to using or registering on the Platform or accessing any material, information or availing any Platform Services through the Platform.
          </p>
          <p>
            By visiting the Platform or setting up/creating an Account on the Platform for availing the Platform Services and clicking on the “I accept” button provided on the Platform, You (“You”, “Your” as applicable) accept and agree to be bound by the terms and conditions of the Privacy Policy. This Privacy Policy is incorporated into and subject to Our terms of use (“Terms”) and shall be read harmoniously and in conjunction with the Terms. All capitalized terms used and not defined in this Privacy Policy shall have the meaning ascribed to them under the Terms.
          </p>
          <p>
            In case You are accessing the Platform as a representative of and on behalf of a legal entity or organisation, (i) You represent and warrant that You have the full authority to represent the legal entity and have power to bind them; and (ii) You understand that the Privacy Policy, upon acceptance by You, is applicable to and binds all other persons accessing or using the Platform and the Platform Services on behalf of the same legal entity or organisation for this purpose.
          </p>
          <p>
            This Privacy Policy (i) is an electronic record under the Information Technology Act, 2000 read with rules and regulations made thereunder and is generated by a computer system; and (ii) will not require any physical, electronic, or digital signature by Invest India.
          </p>
          <b>Collection of Information </b>
          <p>
            We collect, Personal Information, from You when You register or set up an Account with Us on the Platform. You may browse certain sections of the Platform including the ‘Licensing Wizard’ feature of the Platform without registered with Us. However, to avail other Platform Services, You are required to set up an Account with Us.
          </p>
          <p>This Privacy Policy applies to the following information: </p>
          <p>
            <b>Information provided to Us:</b> You may provide certain information to Us voluntarily while registering on Our Platform or while availing the Platform Services on Our Platform including Your name, contact details, company name, email, telephone and fax numbers, and Your areas of interest such as sectors, events, publications, and any other information You choose to provide to Us. We will ask You for certain financial information, including Your bank account details and type, Indian financial system code (IFSC) of bank, to allow You to access the Platform Services. The above information shall be collectively referred to as (the “Personal Information”). The information provided by You to Us either through the Platform or offline may be shared with the relevant Ministries and such information will be stored by such Department/Board either digitally or through other offline modes. In certain cases, the above information may be shared by You with the relevant Ministries offline including through their representatives to access the Platform Services.
          </p>

          <p>
            <b>Non – Personal and Automated Information: </b> We may also collect non – personal information such as Your internet protocol address, geographic location, operating system, referral source, browser type and version, length of visit, page views, website navigation etc. which will not identify with You specifically (“Non - Personal Information”), while You browse, access, or use the Platform. We receive and store Non – Personal Information by use of data collection devices such as “cookies” on certain pages of the Platform in order to help and analyze Our web - page flow, track user trends, measure promotional effectiveness, and promote trust and safety.
          </p>

          <p>
            <b>Transactional Information: </b> If You choose to avail the Platform Services, We will collect information about Your transactions on the Platform including applications for
            registrations and approvals filed, applied for or approvals for registration and licenses obtained from the relevant Ministries. We use this information to provide You updates including progress reports and notify You in relation to the transactions undertaken by You through the Platform.
          </p>

          <p>
            <b>Location based information: </b> When and if use the Platform through Your device, We may receive information about Your location, Your IP address, and/or Your device, including a unique identifier number for Your device. We may use this information to provide You with location-based Platform Services including but not limited to search results and other personalized content. You can withdraw Your consent at any time by disabling the location-tracking functions on Your device. However, this may affect Your enjoyment of certain functionalities on the Platform. In addition to the above, We identify and use Your IP address to also help diagnose problems with Our server, resolve such problems and administer the Platform.
          </p>

          <p>
            <b>User communications: </b>If You choose to post messages, queries or leave feedback, We will collect and store such information You provide to Us on the Platform including name, email address, country, sector, and query. We retain this information as necessary to resolve disputes, provide customer support, respond to queries, and inquires, and troubleshoot problems and improve the Platform Services. If You send us correspondence, such as emails or letters, or if other users or third parties send us correspondence about Your activities or postings on the Platform, We may collect and retain such information into a file specific to You for responding to Your request and addressing concerns in relation to Your use of the Platform.
          </p>
          <p>
            Based on the Approval and Registration applied for on the Platform by You, other information and documents as may be required by the relevant Department/Board may be requested and We may collect such information from You for and on behalf of such relevant Department/Board (“Approval Information”).
          </p>
          <p>
            We may ask You to provide certain additional information about Yourself on a case-to-case basis, depending on the request from the relevant Department/Board. All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity, genuineness, misrepresentation, fraud, negligence of the information disclosed shall lie on Invest India nor will Invest India be in any way responsible to verify any information
          </p>
          <p>
            obtained from You. If You disclose to Us the Personal Information of another person, You hereby represent and warrant that You have obtained the express consent of such person for sharing such information with Us and that the information will be processed in accordance with the terms of this Privacy Policy.
          </p>
          <p>
            All transactional information including financial information gathered by Invest India shall be stored on servers, log files and any other storage system owned by third parties.
          </p>
          <p>
            Our primary goal in collecting the aforementioned information is to provide You a safe, efficient, smooth, and customized experience on the Platform and accessing Platform Services. The information collected by Us and that on behalf of relevant Department/Board allows You to access the Platform Services. More importantly, while doing so, We collect the above - mentioned Personal Information and Approval Information from You that We consider absolutely necessary for achieving this purpose.
          </p>
          <p>
            In general, You can browse the Platform and view the KYA feature without telling Us who You are or revealing any Personal Information about Yourself. In such a case, we will only collect and store the Non – Personal Information. Once You give Us Your Personal Information, You are not anonymous to Us. Wherever possible, while providing the information to Us, We indicate which fields are mandatory and which fields are optional for You. You always have the option to not provide the Personal Information to Us through the Platform by choosing to not use a particular Platform Service or feature being provided by Us on the Platform, which requires You to provide such information. We use this information to do internal research on Your demographics, interests, and to better understand, protect and serve You. This information is compiled and analysed by Us on an aggregated basis and not individually, in a manner that does not specifically identify You.
          </p>
          <p>
            We shall be entitled to retain Your Personal Information and other information for such duration as may be required for the purposes specified hereunder or as may be otherwise required by the applicable law and will be used only in accordance with this Privacy Policy.
          </p>
          <b>Use of Information </b>
          <p>
            We use the Personal Information, Non-Personal Information and other information for the following: (i) to provide and improve the Platform Services and features on the Platform; (ii) administer the Platform efficiently; (iii) to enable Your use of the information available on the Platform; (iv) to send You email notifications that You have specifically requested for (v)to handle inquiries and complaints made by or about You relating to the Platform; (vi) to keep the Platform secure and to detect, prevent and protect Us from any errors, fraud and other criminal or prohibited activity on the Platform; (vii) to enable payments through the Platform; (ix) to help promote a safe service on the Platform and protect the security and integrity of the Platform, the Platform Services and the users; (x) to verify and ensure compliance with the Terms; (xi) to contact You including to enable access to the Platform Services; and (xii) for any other purpose after obtaining Your consent at the time of collection.
          </p>
          <p>
            We may occasionally ask You to complete online surveys or questionaries, these surveys may ask You for Your contact information. We use Your Personal Information to send You promotional emails, publications, newsletter, invitations relating to Our activities and the like as You may select, request or which We consider could be of interest to you. However, We will provide You the ability to opt-out of receiving such emails from Us. If You opt out, Invest India may still send You non-promotional emails, such as emails about the Platform Services and Your account on the Platform.
          </p>
          <p>
            Invest India may use the information (except Approval Information) collected by Us by Your use of the Platform or availing of Platform Services and other information received from the online surveys or questionnaires for conducting analytics and research and sharing reports and insights with third parties in an anonymized manner for publishing research articles or for any other purpose as may be deemed fit by Us.
          </p>
          <b>Cookies </b>
          <p>
            We may use both "session" cookies and "persistent" cookies on the Platform. Session cookies will be deleted from Your computer or device when You close Your browser. Persistent cookies will remain stored on Your computer or device until deleted, or until they
            reach a specified expiry date. We use the session cookies to keep track of You whilst You navigate and access the Platform.
          </p>
          <p>
            We will use persistent cookies to enable the Platform to recognize You when You visit and keep track of Your preferences in relation to Your use of the Platform. We use “cookies” to allow You to enter Your password less frequently during a session on the Platform.
          </p>
          <p>
            You are always free to decline Our cookies if Your browser permits, although in that case, You may not be able to use certain features or Platform Services being provided on the Platform or You may be required to re-enter Your password each time you log – in or access the Platform during a session.
          </p>
          <p>
            We also use Google Analytics to analyse the use of the Platform wherein statistical and other information regarding the use of the Platform is generated by means of cookies which are stored on Your computer or devices. The information generated relating to Our Platform is used to create reports about the use of the Platform and Google stores such information independently, and it is governed by their privacy policy available at www.google.com/privacypolicy.html.
          </p>
          <b>Sharing of Information </b>
          <p>
            We may disclose Your Personal Information to Our employees, officers, agents and third party including relevant Ministries, payment gateways or other service providers for the purpose of providing Platform Services through the Platform, who shall use it only for the purposes for which We disclose it to them. This disclosure may be required for Us, for instance, to undertake audits or data analysis, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to Our Platform Services.
          </p>
          <p>
            We will also share and disclose the Approval Information with the relevant Department/Board Ministries for enabling the application process for the Approvals and Registrations and the relevant Department/Board shall use, disclose, store, and retain Your Approval Information in the manner they deem fit.
          </p>
          <p>
            We do not disclose Your Personal Information to third parties for their marketing and advertising purposes without Your explicit consent. Unless and until, You explicitly give Your consent to Us,
            to do so, We will not share Your Personal Information with another user of the Platform and vice versa.
          </p>
          <p>
            Invest India may disclose Your information, to the extent necessary: (i) to comply with laws and to respond to lawful requests and legal process or proceedings, (ii) to protect the rights and property of Invest India, Our users, and others, including to enforce the Terms, and (iii) in an emergency to protect the personal safety and assets of Invest India, the User, or any person. In such an event Invest India is in no manner responsible for informing You or seeking Your approval or consent.
          </p>
          <b>Security Precautions and Measures </b>
          <p>
            Our Platform has adopted all reasonable security measures and safeguards to protect Your privacy and Personal Information from loss, misuse, unauthorized access, disclosure, destruction, and alteration of the information in compliance with applicable laws. Further, whenever You change or access Your Account on the Platform or any information relating to it, We offer the use of a secure server. As long as You access and/or use the Platform (directly or indirectly) it is Your obligation, at all times, to take adequate physical, managerial, and technical safeguards, at Your end, to preserve the integrity and security of Your data which shall include and not be limited to Your Personal Information.
          </p>
          <p>
            You will be responsible for maintaining the confidentiality of the Account information and are fully responsible for all activities that occur under Your Account. You agree to (a) immediately notify Us of any unauthorized use of Your Account information or any other breach of security, and (b) ensure that You exit from Your Account at the end of each session. Invest India cannot and will not be liable for any loss or damage arising from Your failure to comply with this provision. You may be held liable for losses incurred by Invest India or any other user of or visitor to the Platform due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account information secure and confidential.
          </p>
          <p>
            When payment information is being transmitted on or through the Platform, it will be protected by encryption technology. You expressly consent to the sharing of Your information with third party service providers, including payment gateways, to process payments and manage your payment-related information. Hence, Invest India cannot guarantee that transmissions of Your
            payment-related information or Personal Information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by Us or Our third-party service providers. Invest India does not assume any liability or responsibility for disclosure of Your information due to errors in transmission, unauthorized third-party access, or other causes beyond its control. You play an important role in keeping Your Personal Information secure. You shall not share Your Personal Information or other security information for Your Account with anyone. Invest India has undertaken reasonable measures to protect Your rights of privacy with respect to Your usage of the Platform controlled by Us and Our Platform Services. However, We shall not be liable for any unauthorized or unlawful disclosures of Your Personal Information made by any third parties who are not subject to Our control.
          </p>
          <b>
            Links to other Third-party Sites and collection of information
          </b>
          <p>
            Our Platform may link You to other third - party Platforms (“Third – Party Sites”) that may collect Your Personal Information including Your IP address, browser specification, or operating system. We are not in any manner responsible for the security of such information or their privacy practices or content of those Third – Party Sites. Additionally, You may also encounter “cookies” or other similar devices on certain pages of the Third – Party Sites and it is hereby clarified by Invest India that the Platform does not control the use of cookies by these Third – Party Sites. These third-party service providers and Third-Party Sites may have their own privacy policies governing the storage and retention of Your information that You may be subject to. This Privacy Policy does not govern any information provided to, stored on, or used by these third-party providers and Third-Party Sites. We recommend that when You enter a Third-Party Site, You review the Third-Party Site’s privacy policy as it relates to safeguarding of Your information. You agree and acknowledge that We are not liable for the information published in search results or by any Third-Party Sites.
          </p>
          <li>
            <b>Public Posts: </b>You may provide Your feedback, reviews, testimonials, etc. on the Platform on Your use of the Platform Services provided by Invest India (“Posts”). Any content or Personal Information and Posts that You share or upload on the publicly viewable portion of the Platform (on discussion boards, in messages and chat areas, etc.) will be publicly available, and can be viewed by other users and any and all intellectual property rights in and to such Posts shall vest with Invest India. Your Posts shall have to comply with the conditions relating to Posts as mentioned in the Terms. Invest India retains an unconditional right to remove and delete any Post or such part of the Post that, in Our opinion, does not comply with the conditions in the Terms. Invest India reserves the right to use, reproduce and share Your Posts for any purpose. If You delete Your Posts from the Platform, copies of such Posts may remain viewable in archived pages, or such Posts may have been copied or stored by other Users.
          </li>

          <p>Your Consent and Changes to Privacy Policy </p>
          <p>
            <b>Withdrawal of consent:</b> You may choose to withdraw Your consent provided hereunder at any point in time. Such withdrawal of the consent must be sent in writing to contact
            us- departmentalexamtechsprt@gmail.com. In case You do not provide Your consent or later withdraw Your consent, We request you not to access the Platform and use the Platform Services and reserve the right to not provide You any Platform Services on the Platform. In such a scenario, Invest India may delete Your information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to You. In the event, Invest India retains the Personal Information post withdrawal or cancellation of Your consent, it shall retain it only for the period permitted under applicable laws. If the Personal Information is to be corrected or updated, please contact us at contact us-departmentalexamtechsprt@gmail.com.

          </p>
          <p>
            <b>Changes To Our Privacy Policy: </b> We reserve the unconditional right to change, modify, add, or remove portions of this Privacy Policy at any time, and shall provide a notice to You of such changes. Any changes or updates will be effective immediately. You can determine if changes have been made by checking the “Last Updated” legend above. Your acceptance of the amended Privacy Policy shall signify Your consent to such changes and agreement to be legally bound by the same.
          </p>
          <p>
            You may instruct Us at any point to provide You with any Personal Information We hold about You. Please note that the provision of such information shall be subject to the supply of appropriate evidence (as may be deemed necessary by Invest India) of Your identity.
          </p>
          <p>
            <b>Grievance</b>
            If You have any grievance with respect to the Platform or the Platform Services, You may write to us on contact us- departmentalexamtechsprt@gmail.com.

          </p>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy