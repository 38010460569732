
import './styles.css'
const QuestionBank = (props) => {


    return (
        <>
            <div style={{ padding: '5px 20px' }} class="card question-card">
                <h4 class="underline">Question {props.index}: </h4>
                <p class="questionBody individual_question">{props.question.ques}</p>
                <div class="options">
                    <ol class="options-list">
                        <li style={props.question.ans == 1 ? { background: "#ffb3b3" } : {}} class="option">{props.question.opt1}</li>
                        <li style={props.question.ans == 2 ? { background: "#ffb3b3" } : {}} class="option">{props.question.opt2}</li>
                        <li style={props.question.ans == 3 ? { background: "#ffb3b3" } : {}} class="option">{props.question.opt3}</li>
                        <li style={props.question.ans == 4 ? { background: "#ffb3b3" } : {}} class="option">{props.question.opt4}</li>
                    </ol>
                </div>
            </div>
        </>
    )
}

export default QuestionBank