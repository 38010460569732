import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { loaderEnd, loaderStart } from '../../components/loader';
import Table from 'react-bootstrap/Table';
import ModalLayout from '../../components/modalLayout';
import { saveAs } from 'file-saver'

import './Application.css'
import { downloadRes, downloadCertificate } from "./api";
const Application = (props) => {

    const [modal, setModal] = useState(false)
    const [allResult, setAllResult] = useState([])

    const updateData = async () => {
        setModal(true)
        loaderStart()
        if (props.auth.auth?.data && props.auth.auth.data.aadhar) {
            let res = await downloadRes(props.auth.auth.data.aadhar)
            if (res.code) {
                setAllResult(res.data)
                // window.open(process.env.REACT_APP_RESULT_URL + res.result[0].filepath, "_blank")
            }
            else {
                alert("No result available.")
                setModal(false)
            }
        }
        loaderEnd()
    }

    const handleCertificate = async () => {
        loaderStart()
        let res = await downloadCertificate()
        if (res.code && res.data.length) {
            // console.log(process.env.REACT_APP_CERTIFICATE+res.data[0].certificate_path);
            // fetch(process.env.REACT_APP_CERTIFICATE+res.data[0].certificate_path).then(response => {
            //     response.blob().then(blob => {
            //         // Creating new object of PDF file
            //         const fileURL = window.URL.createObjectURL(blob);
            //         // Setting various property values
            //         let alink = document.createElement('a');
            //         alink.href = fileURL;
            //         alink.download = 'SamplePDF.pdf';
            //         alink.click();
            //     })
            // })
            window.open(process.env.REACT_APP_CERTIFICATE + res.data[0].certificate_path, "_blank")
        }
        else {
            alert("No certificate available.")
        }
        loaderEnd()
    }

    return (
        <>
            <div className="card application-box">
                <div className="card-header">
                    Please Read the Instruction Carefully / सावधानी से निर्देश पढ़ें
                </div>

                <div className='application-body row'>
                    <div className='col-md-6'>
                        <div className="card">
                            <div className="card-header card-header-custom">
                                Fill in Your Application
                            </div>
                            <div className="card-body">
                                {/* <h5 className="card-title">Fill in Your Application</h5> */}
                                <p className="card-text">नोट- आप अभ्यास सत्र में सम्मिलित होने के उपरांत या सीधे ही इस बटन को Click कर वास्तविक परीक्षा में शामिल होने के लिए आवेदन पत्र भर सकते हैं।.</p>
                                <Link to="/application-form" className="btn btn-primary"><i className='fa fa-paper-plane'></i> &nbsp;Apply</Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="card">
                            <div className="card-header card-header-custom">
                                Practice Exam Slot Booking
                            </div>
                            <div className="card-body">
                                {/* <h5 className="card-title">Practice Exam Slot Booking</h5> */}
                                <p className="card-text">नोट- राजस्व पर्षद के द्वारा परीक्षार्थियों को यह सुविधा प्रदान की जा रही है कि यदि वे चाहें तो किसी भी विषय के लिए वे अभ्यास-सत्र को चुन कर उस विषय के लिए अभ्यास कर सकते हैं। कम्प्यूटर के समक्ष उपस्थित होकर चयनित विषय के Question को हल करेंगे यह कार्य मात्र अभ्यास के लिए है ताकि वें इस नई पद्वति से भलि-भांति अवगत हो जाये।.</p>
                                <a href="http://206.189.143.221:7073/language" rel="noreferrer" target='_blank' className="btn btn-primary"><i className='fa fa-calendar'></i> &nbsp;Practice</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='application-body row'>
                    <div className='col-md-6'>
                    <div className="card">
                        <div className="card-header card-header-custom">
                            Download your Admit Card
                        </div>
                        <div className="card-body">
                            {/* <h5 className="card-title">Fill in Your Application</h5> */}
                            <p className="card-text">You Can Download Your Admit Card from here</p>
                            <Link to="/admit-card" className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download</Link>
                        </div>
                    </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="card">
                        <div className="card-header card-header-custom">
                            View Certificate / Result
                        </div>
                        <div className="card-body">
                            {/* <h5 className="card-title">Practice Exam Slot Booking</h5> */}
                            <p className="card-text">You can view your Certificate / Result. Click here to</p>
                            <a href="#" onClick={() => handleCertificate()} className="btn btn-primary mb-1"><i className='fa fa-download'></i> &nbsp;Download Certificate</a> &nbsp;
                            <a href="#" onClick={() => updateData(true)} className="btn btn-primary"><i className='fa fa-download'></i> &nbsp;Download Result</a>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {
                modal
                    ?
                    <ModalLayout
                        modal={modal}
                        title="Results"
                        toggle={() => setModal(false)}
                    >
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allResult.map((a, i) => {
                                        return (<tr>
                                            <td>{i + 1}</td>
                                            <td>{
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    onClick={() => saveAs(process.env.REACT_APP_RESULT_URL + a, a)}
                                                >Download</button>
                                            }</td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </ModalLayout>
                    : null
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

export default connect(mapStateToProps)(Application);
