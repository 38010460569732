import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { dateBasicFormant, dateDev } from "../../shared/dateFn"
import "./styles.css";
const DatePickerSection = (props) => {
    const handleDate = (name, date) => {
        let _date = dateDev(date);
        props.handleDateChange(name, _date);
    }


    console.log(props);

    return (
        <DatePicker
            style={{ width: '100%' }}
            popperPlacement={"bottom"}
            required={props.required}
            className="date-width"
            placeholderText="dd/mm/yyyy"
            dateFormat="dd/MM/yyyy"
            selected={props.date ? dateBasicFormant(props.date) :""}
            onChange={(date) => handleDate(props.name, date)}
            excludeDates={props.excludeDates}
            maxDate={props.maxDate}
            minDate={props.minDate}
            showMonthYearDropdown={props.showMonthYearDropdown}
            highlightDates={props.highlightWithRanges}
        />
    )
}

export default DatePickerSection;