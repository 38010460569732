import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './App.css';
import Layout from './Pages/Layout';
import { Routes, Route } from 'react-router-dom'
import HomePage from './Pages/HomePage';
import Login from './Pages/Login/Login';
import Logout from './Pages/Logout';

import Register from './Pages/Register/Register';
import Application from './Pages/Application/Application';
import ApplicationForm from './Pages/Application-form/ApplicationForm';
import Step2 from './Pages/Step2/Step2';
import Step3 from './Pages/Step3/Step3';
import Payment from './Pages/PaymentSuccess';
import PaymentFail from './Pages/PaymentFail';

import ApplicationFormat from './Pages/Application-format/ApplicationFormat';
import CertificateCheck from './Pages/Certificate-check/CertificateCheck';
import PrivacyPolicy from './Pages/Privacy-policy/PrivacyPolicy';
import RefundPolicy from './Pages/Refund-policy/RefundPolicy';
import QuestionBank from './Pages/Question-bank/QuestionBank';
import SubjectPage from './Pages/Subject-page/SubjectPage';
import AdmitCard from './Pages/AdmitCard';
import ChapterPage from './Pages/Chapter-page/ChapterPage';
import CheckCode from './Pages/Check-Qrcode';
import Questions from './Pages/Questions';


// import PaymentSuccess from './Pages/PaymentSuccess';

import { getDataFromLocal } from "./store/auth/actions"
const App = (props) => {

    useEffect(() => {
        props.getDataFromLocal();
    }, [])

    console.log(process.env);

    return (
        <div className="app">
            {
                props.auth.auth?.token
                    ?
                    <Routes>
                        <Route path='/' element={<Layout />}>
                            <Route index element={<HomePage />} />
                            <Route path='application' element={<Application />} />
                            <Route path='application-form' element={<ApplicationForm />} />
                            <Route path='step2' element={<Step2 />} />
                            <Route path='step3' element={<Step3 />} />
                            <Route path='payment-response/:id' element={<Payment />} />
                            <Route path='payment-responsefail/:id' element={<PaymentFail />} />
                            <Route path='application-format' element={<ApplicationFormat />} />
                            <Route path='certificate-check' element={<CertificateCheck />} />
                            <Route path='privacy-policy' element={<PrivacyPolicy />} />
                            <Route path='refund-policy' element={<RefundPolicy />} />
                            <Route path='question-bank' element={<QuestionBank />} />
                            <Route path='subject-page' element={<SubjectPage />} />
                            <Route path='chapter-page/:code' element={<ChapterPage />} />
                            <Route path='questions' element={<Questions />} />
                            {/* <Route path='payment' element={<PaymentSuccess />} /> */}

                        </Route>
                        <Route path='admit-card' element={<AdmitCard />} />
                        <Route path='check-qrcode/:id' element={<CheckCode />} />
                    </Routes>
                    :
                    <Routes>
                        <Route path='/' element={<Layout />}>
                            <Route index element={<HomePage />} />
                            <Route path='/login' element={<Login />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path='/register' element={<Register />} />
                            <Route path='application-format' element={<ApplicationFormat />} />
                            <Route path='certificate-check' element={<CertificateCheck />} />
                            <Route path='privacy-policy' element={<PrivacyPolicy />} />
                            <Route path='refund-policy' element={<RefundPolicy />} />
                            <Route path='question-bank' element={<QuestionBank />} />
                            <Route path='subject-page' element={<SubjectPage />} />
                            <Route path='chapter-page/:code' element={<ChapterPage />} />
                            <Route path='questions' element={<Questions />} />
                        </Route>
                        <Route path='check-qrcode/:id' element={<CheckCode />} />
                    </Routes>
            }

            {/* <Layout /> */}
        </div >
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.Auth
    }
}

const mapDispatchToProps = { getDataFromLocal }
export default connect(mapStateToProps, mapDispatchToProps)(App);

