import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import swal from 'sweetalert';
import Wizard from '../Wizard';
import { loaderEnd, loaderStart } from '../../components/loader';
import DatePicker from "../../components/datePicker";
import { getSlots, getCenter, getAvailability, updateAvailability, initiatePayment } from "./api";
import { dayInFuture, dateStandard } from "../../shared/dateFn";
import "./styles.css";
import ModalLayout from '../../components/modalLayout';
import Table from 'react-bootstrap/Table';
import { getPaymentInfo } from "../AdmitCard/api"

const allMonth = [
    // {
    //     id: 12,
    //     year: 2022,
    //     month: "December"
    // },
    // {
    //     id: 1,
    //     year: 2023,
    //     month: "January"
    // },
    {
        id: 2,
        year: 2023,
        month: "February"
    },
    // {
    //     id: 3,
    //     year: 2023,
    //     month: "March"
    // }
]

const Legend = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 10, height: 10, backgroundColor: props.color }}></div>
            &nbsp;{props.title}
        </div>
    )
}

const Step3 = () => {
    const navigate = useNavigate()
    const [allSlot, setAllSlot] = useState([]);
    const [allCenter, setAllCenter] = useState([])
    // const [allMonth, setAllMonth] = useState([])
    const [allSlotRange, setAllSlotRange] = useState([])
    const [disableDay, setDisableDay] = useState([])
    const [allHighlight, setAllHighlight] = useState([])
    const [modal, setModal] = useState(false)
    const [activeDate, setActiveDate] = useState(null)
    const [state, setState] = useState({
        category: null,
        slot_id: null,
        date: "",
        center_id: "",
        month: "",
    })
    useEffect(() => {
        getDataPayment()
        // getData()
        let sunday = dayInFuture(7)
        // let saturday = dayInFuture(6)
        setDisableDay([...sunday])
    }, [])

    const getDataPayment = async () => {
        let payment = await getPaymentInfo()
        if(payment.data){
            
        }
        else{
            navigate("/application")
        }
    }

    const getData = async (id) => {
        loaderStart()
        let res1 = await getCenter(id);
        // console.log(res, res1);
        if (res1.code == 1) {
            setAllCenter(res1.data)
        }
        loaderEnd()
    }


    const showAlert = (allotmentId) => {
        swal("Slot Booking Submitted Successfully", "", "success")
            .then((value) => {
                // navigate('/payment')
                makePayment(allotmentId)
            });
    }

    const makePayment = async (allotmentId) => {
        loaderStart()
        let id = Math.floor(Date.now());
        let amt = state.category == 1 ? 350000 : 110000
        window.location.href = process.env.REACT_APP_API_URL + "form/payment?amount=" + amt + "&hdnOrderID=" + id + "&trnRemarks=&allotmentId=" + allotmentId
        loaderEnd()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (state.date && state.center_id) {
            setModal(true)
        }
        else {
            swal("Please select date, center and slot to proceed", "", "error")
        }
    }

    const finalSubmit = async () => {
        loaderStart()

        let data = {
            "center_id": state.center_id
        }
        //without training
        if (state.category == 0) {
            data = {
                ...data,
                "slot_id": state.slot_id,
                date: state.date,
                "with_training": "NO",
            }
        }
        else {
            data = {
                ...data,
                "slot_id": null,
                "date": state.date,
                "with_training": "YES",
            }
        }
        // console.log(data);
        let res = await updateAvailability(data)
        if (res.code == 1) {
            // showAlert(res.data)
            makePayment(res.data)
        }
        else {
            swal(res.message, "", "error")
        }

        loaderEnd()
    }

    const updateData = (e, name) => {
        // console.log(e);
        let _state = { ...state }
        if (name === "date") {
            _state[name] = e;
            _state["slot_id"] = "";
        }
        else
            _state[e.target.name] = e.target.value;
        if(e?.target?.name === "category"){
            setAllCenter([])
            _state["center_id"] = "";
            _state["date"] = "";
            _state["slot_id"] = "";
            getData(e.target.value)
        }
        if(e?.target?.name === "center_id"){
            _state["date"] = "";
            _state["slot_id"] = "";
        }
        // console.log(_state);
        setState(_state);
    }

    useEffect(() => {
        if (state.center_id && state.category) {
            getAvail();
        }
    }, [state.category, state.center_id])

    useEffect(() => {
        if (state.category == 1 && state.center_id && state.month) {
            let filter = allMonth.filter((f) => f.id == state.month)
            if (filter.length > 0)
                getAvail(filter[0].year + "-" + state.month + "-01");
        }
    }, [state.category, state.center_id, state.month])

    const getAvail = async (month) => {
        let res = await getAvailability(state.center_id, state.category, month)
        if (res.code) {
            if (state.category == 0) {
                setActiveDate(res.data)
            }
            else {
                setAllSlotRange([res.data])
            }

        }

    }

    useEffect(() => {
        if (state.center_id && state.category && state.date) {
            getSlotsData();
        }
    }, [state.category, state.center_id, state.date])

    const getSlotsData = async () => {
        let res = await getSlots(state.center_id, state.category, state.date)
        if (res.code) {
            setAllSlot(res.data)
        }

    }

    const getSlot = (id) => {
        let filter = allSlot.filter((a) => a.id == id);
        // console.log(allSlot, filter, id);
        if (filter.length) {
            return filter[0].start_time + " - " + filter[0].end_time
        }
    }

    return (
        <>
            <div className="card  application-box">
                <Wizard
                    title="Book Slot"
                    redirect='/step2'
                />
                <form className="row g-3" onSubmit={handleSubmit}>
                    <div id="applications" className='application-body application-form-box'>
                        <div className='card application-form'>
                            <center> <h4> Slot Details</h4></center>
                            <p align="center"><i style={{ color: 'red', fontSize: '16px' }} >
                                Exam will be conducted only on saturdays of every month for without training candidates.
                                <br />Slots will be announced every two weeks for with training candidates. <br />Training will be conducted with minimum of 25 candidates else next slot wil be alloted. </i></p>

                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Exam Category</label>
                                <div className="col-sm-6">
                                    <select
                                        className="form-select"
                                        id="autoSizingSelect"
                                        name={"category"}
                                        required
                                        value={state.category}
                                        onChange={(e) => updateData(e)}
                                    >
                                        <option selected value=''>Select Exam Category</option>
                                        <option value="0">Exam without training</option>
                                        <option value="1">Exam with training</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row  mt-3">
                                <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Center</label>
                                <div className="col-sm-6">
                                    <select
                                        className="form-select"
                                        id="autoSizingSelect"
                                        name={"center_id"}
                                        required
                                        value={state.center_id}
                                        onChange={(e) => updateData(e)}
                                    >
                                        <option selected value=''>Select Center</option>
                                        {
                                            allCenter?.map((a) => (
                                                <option value={a.id}>{a.center}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                state.category == 0 && state.center_id
                                    ?
                                    <>
                                        {activeDate && <div className="row  mt-3">
                                            <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Your Examination Date</label>
                                            <div className="col-sm-6">
                                                <DatePicker
                                                    name={"date"}
                                                    date={state.date ? new Date(state.date) : ""}
                                                    required={false}
                                                    handleDateChange={(name, e) => updateData(e, name)}
                                                    // excludeDates={disableDay}
                                                    maxDate={activeDate ? new Date(activeDate) : null}
                                                    // minDate={new Date("2023-01-16")}
                                                    minDate={activeDate ? new Date(activeDate) : null}
                                                    highlightWithRanges={allHighlight}
                                                />
                                                {/* <input type="date" className="form-control" id="inputEmail3" /> */}
                                            </div>
                                        </div>}
                                        <div className="row  mt-3">
                                            <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Your Examination Slot</label>
                                            <div className="col-sm-6">
                                                <select
                                                    className="form-select"
                                                    id="autoSizingSelect"
                                                    name={"slot_id"}
                                                    required
                                                    value={state.slot_id ? state.slot_id : ''}
                                                    onChange={(e) => updateData(e)}
                                                >
                                                    <option value='' selected>Select Slot</option>
                                                    {
                                                        allSlot?.map((a) => (
                                                            <option value={a.id}>{a.start_time} - {a.end_time}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="legend-section">
                                            <Legend color="green" title="Available" />
                                            <Legend color="red" title="Full" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        {
                                            state.category == 1 && state.center_id
                                                ?
                                                <>
                                                    {/* <div className="row  mt-3">
                                                        <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please Select Month</label>
                                                        <div className="col-sm-6">
                                                            <select
                                                                className="form-select"
                                                                id="autoSizingSelect"
                                                                name={"month"}
                                                                required
                                                                value={state.month}
                                                                onChange={(e) => updateData(e)}
                                                            >
                                                                <option value='' selected>Select Month</option>
                                                                {
                                                                    allMonth?.map((a) => (
                                                                        <option value={a.id}>{a.month}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className="row  mt-3">
                                                        <label for="inputEmail3" className="col-sm-4 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>Please select Slot range</label>
                                                        <div className="col-sm-6">
                                                            <select
                                                                className="form-select"
                                                                id="autoSizingSelect"
                                                                name={"date"}
                                                                required
                                                                value={state.date}
                                                                onChange={(e) => updateData(e)}
                                                            >
                                                                <option value='' selected>Select slot range</option>
                                                                {
                                                                    allSlotRange?.map((a) => (
                                                                        <option value={a}>{dateStandard(a)}(Monday) till Friday</option>
                                                                    ))
                                                                }
                                                                {/* <option value="2023-02-06">06-02-2023(Monday) till Friday</option> */}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </>
                            }
                        </div>
                    </div>
                    <div className="d-grid gap-2">
                        <button className="btn btn-primary" type="submit"><i className="fa fa-next"></i>&nbsp;Submit and Pay Now</button>
                    </div>
                </form>
                {
                    modal
                        ?
                        <ModalLayout
                            modal={modal}
                            title="Summary"
                            toggle={() => setModal(false)}
                        >

                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Exam Category</th>
                                        <th>Center</th>
                                        {state.category == 0 && <th>Slot</th>}

                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{state.category == 1 ? "WITH TRAINING" : "WITHOUT TRAINING"}</td>
                                        <td>{allCenter.filter((a) => a.id == state.center_id)[0].center}</td>
                                        {state.category == 0 && <td>{getSlot(state.slot_id)}</td>}

                                        <td>{state.category == 0 ? dateStandard(state.date) : dateStandard(state.date)+"(Monday) till Friday"}</td>
                                    </tr>
                                </tbody>
                            </Table>

                            <button
                                className="btn btn-primary"
                                type="submit"
                                onClick={() => finalSubmit()}
                            >Submit and pay now</button>
                        </ModalLayout>
                        : null
                }

            </div>
        </>
    )
}

export default Step3