import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { BounceLoader } from 'react-spinners';
import { loaderEnd, loaderStart } from '../../components/loader';
import { LoginData } from '../../information/actions';
import { getDataAfterLogin } from '../../store/auth/actions';

import swal from 'sweetalert';
import './login.css'

const Login = (props) => {
  const navigate = useNavigate()
  const [reCaptcha, setReCaptcha] = useState(false)
  let [val, setVal] = useState({
    aadhar: '',
    dob: '',
  })

  // const onLogin = () => {
  //   setLoading(!loading)

  //   setTimeout(() => {
  //     navigate('/application')
  //   }, 2000);
  // }

  // const onLogin = () => {
  //   loaderStart()
  //   navigate('/application')
  //   loaderEnd()

  // }
  const changeHandle = (e) => {
    setVal({ ...val, [e.target.name]: e.target.value })
  }


  const onLogin = async (e) => {
    e.preventDefault()
    loaderStart()
    if (reCaptcha) {
      let sendval = {
        aadhar: val.aadhar,
        dob: val.dob,
      }
      let res = await LoginData(sendval)
      if (res.code === 1) {
        loaderStart()
        localStorage.setItem("BIPARD-registration", JSON.stringify({
          data: res.result,
          token: res.token
        }))
        props.getDataAfterLogin({
          data: res.result,
          token: res.token
        })
        swal(res.msg, "", "success")
        navigate('/application')
      }
      else {
        swal(res.msg, "", "error")
      }
    }
    else if (val.aadhar === '') {
      swal("Please Enter Aadhar Number", "", "error")
    }
    else if (val.dob === '') {
      swal("Please Enter DOB", "", "error")
    }
    else {
      swal("Please Verify captcha to Continue", "", "error")
    }
    loaderEnd()
  }

  const captchaClick = (e) => {
    setReCaptcha(e);
  }
  console.log(props.auth);
  return (
    <>
      {/* {
        loading ? <BounceLoader
          color={color}
          loading={loading}
          cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        /> :  */}
      <section className='login'>
        <div className='register-container'>
          <div className='instruction'>
            <div className="col-sm-12" >
              <h3>GENERAL INSTRUCTIONS / सामान्य निर्देश:</h3><hr />
              <ul >
                <li><h4 align="justify">Incomplete application form shall be rejected.</h4></li>
                <li><h4 align="justify">अपूर्ण आवेदन पत्र स्वीकार्य नहीं होंगे |</h4></li>
                <li><h4 align="justify">Please read all the instructions carefully before filling up the form.</h4></li>
                <li><h4 align="justify">कृप्या प्रपत्र भरने से पहले सभी निर्देशों को ध्यानपूर्वक पढ़ें |</h4></li>
                <li><h4 align="justify">Applicant’s signature is mandatory on the application form.</h4></li>
                <li><h4 align="justify">आवेदन पत्र पर प्रार्थी का हस्ताक्षर अनिवार्य है ।</h4></li>
                <li><h4 align="justify">Please make sure the details mentioned in the application form should be same as the details to be filled up in the examination hall.</h4></li>
                <li><h4 align="justify">कृपया सुनिश्चित करें कि आवेदन पत्र में उल्लिखित विवरण परीक्षा हॉल में भरे जाने वाले विवरण के समान होना चाहिए ।</h4></li>
              </ul>
            </div>
          </div>
          <div className='form'>
            <div className='form-container'>

              <div className="wrapper">
                <div className="title"><span><h3>Candidate Login</h3></span></div>
                <form type='submit'>
                  <p align="right"><i style={{ color: 'red', fontSize: '16px' }} s>Fields marked with (*) are mandatory</i></p>
                  <div className="row">
                    <label className="col-sm-6 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fas fa-mobile">&nbsp;</i> Aadhaar Number/आधार संख्या</label>
                    <div className="col-sm-6">
                      <input
                        type="number"
                        placeholder="Aadhar Number"
                        required
                        name='aadhar'
                        value={val.aadhar}
                        className="form-control p-2"
                        onChange={changeHandle}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-6 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> <i className="fas fa-calendar">&nbsp;</i>Password - Date of Birth</label>
                    <div className="col-sm-6">
                      <input
                        type="date"
                        placeholder="Password"
                        required
                        className="form-control p-2"
                        name='dob'
                        value={val.dob}
                        onChange={changeHandle}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <label className="col-sm-4 col-form-label"> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fas fa-shield">&nbsp;</i>Click To Verify</label>
                    <div className="col-sm-8">
                      <ReCAPTCHA
                        sitekey={"6LcgtoMjAAAAANRHf5wiCV4Z2Bg6kRZs1wSunjBQ"}
                        onChange={captchaClick}
                      />
                    </div>
                  </div>

                  {/* <div className="pass"><a href="#">Forgot password?</a></div> */}
                  <div className="row mt-5 button">
                    {/* <Link to='/application'> */}
                    <input onClick={onLogin} type="submit" value="Login" />
                    {/* </Link> */}
                  </div>
                  <div className="signup-link">Not a member? <a href="/register">Signup now</a></div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* } */}

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.Auth
  }
}

const mapDispatchToProps = { getDataAfterLogin }
export default connect(mapStateToProps, mapDispatchToProps)(Login);
