import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './register.css'
import { BounceLoader } from 'react-spinners';
import ReCAPTCHA from "react-google-recaptcha";
import { EnterOtp, RegisterData } from '../../information/actions';
import swal from 'sweetalert';
import { loaderEnd, loaderStart } from '../../components/loader';


const Register = () => {
    const navigate = useNavigate()
    const [mobile, setMobile] = useState();
    const [reCaptcha, setReCaptcha] = useState(false)
    const [val, setVal] = useState({
        name: "",
        aadhar: "",
        service: "",
        service_cadre: "",
        mobile: "",
        email: "",
        dob: "",
        otp: "",
    })

    // const onRegister = () => {
    //   loaderStart()
    //   // setTimeout(() => {
    //   navigate('/login')
    //   // }, 2000);
    //   loaderEnd()
    // }
    const captchaClick = (e) => {
        setReCaptcha(e);
    }
    const handleChange = (e) => {
        setVal({ ...val, [e.target.name]: e.target.value })
    }

    const onRegister = async (e) => {
        e.preventDefault()
        loaderStart()
        if (reCaptcha) {
            console.log(val, val.aadhar.length);
            if (val.name === '') {
                swal("Please Enter Your Name", "", "error")
            }
            else if (val.aadhar === '' ||  (val.aadhar.length != 12 && val.aadhar)) {
                swal("Please Enter correct aadhar number", "", "error")
            } else if (val.service === '') {
                swal("Please Select Service", "", "error")
            } else if (val.service_cadre === '') {
                swal("Please Select Service Cadre", "", "error")
            } else if (val.email === '') {
                swal("Please Enter Email Id", "", "error")
            } else if (val.dob === '') {
                swal("Please Enter Date of Birth", "", "error")
            }
            else {
                let sendval = {
                    name: val.name,
                    aadhar: val.aadhar,
                    service: val.service,
                    service_cadre: val.service_cadre,
                    mobile: mobile,
                    email: val.email,
                    dob: val.dob,
                    capcha: "asdas",
                    otp: val.otp
                }
                let res = await RegisterData(sendval)
                console.log(res);
                if (res.code === 1) {
                    swal("Your registration is successful. Please login to register for exam.", "", "success")
                    navigate('/login')
                } else {
                    swal("Something went wrong. Please try again.", "", "error")
                }
            }
        }
        else {
            swal("Please Verify captcha to Continue", "", "error")
        }
        loaderEnd()
    }

    const handleOtp = async () => {
        if (mobile) {
            let val = {
                phone: mobile,
            }
            let res = await EnterOtp(val)
            if (res.code === 1) {
                swal(res.msg, "Please check Your Mobile Or Email for Otp", "success")
                    .then((value) => {
                        return
                    });
            }
        }
        else {
            swal("Please Enter Mobile Number Continue", "", "error")
        }
    }

    return (
        <>
            <section className='login'>
                <div className='register-container'>
                    <div className='instruction'>
                        <div className="col-sm-12 col-md-12 " >
                            <h3>GENERAL INSTRUCTIONS / सामान्य निर्देश:</h3><hr />
                            <ul >
                                <li><h4 align="justify">Incomplete application form shall be rejected.</h4></li>
                                <li><h4 align="justify">अपूर्ण आवेदन पत्र स्वीकार्य नहीं होंगे |</h4></li>
                                <li><h4 align="justify">Please read all the instructions carefully before filling up the form.</h4></li>
                                <li><h4 align="justify">कृप्या प्रपत्र भरने से पहले सभी निर्देशों को ध्यानपूर्वक पढ़ें |</h4></li>
                                <li><h4 align="justify">Applicant’s signature is mandatory on the application form.</h4></li>
                                <li><h4 align="justify">आवेदन पत्र पर प्रार्थी का हस्ताक्षर अनिवार्य है ।</h4></li>
                                <li><h4 align="justify">Please make sure the details mentioned in the application form should be same as the details to be filled up in the examination hall.</h4></li>
                                <li><h4 align="justify">कृपया सुनिश्चित करें कि आवेदन पत्र में उल्लिखित विवरण परीक्षा हॉल में भरे जाने वाले विवरण के समान होना चाहिए ।</h4></li>

                                <li><h4 align="justify">Please select the date, month & year correctly. Your Date of Birth will be your PASSWORD .</h4></li>

                                <li><h4 align="justify"> कृप्या तिथि, महीना और वर्ष सही तरीके से चुनें। आपकी जन्मतिथि आपका पासवर्ड होगा.</h4></li>

                                <li><h4 align="justify">Mobile number is important to receive One Time Password (OTP)/वन टाइम पासवर्ड (OTP) प्राप्त करने के लिए मोबाइल नंबर महत्वपूर्ण है।</h4></li>
                            </ul>
                        </div>
                    </div>
                    <div className='form'>
                        <div className='form-container'>
                            <div className="wrapper">
                                <div className="title"><span> <h3>Candidate Registration</h3></span></div>

                                <form onSubmit={onRegister} className='row g-3'>
                                    <p align="right"><i style={{ color: 'red', fontSize: '16px' }}>Fields marked with (*) are mandatory</i></p>
                                    <div className="row">
                                        <label htmlFor="inputEmail3" className="col-sm-6 col-form-label"><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fa fa-user">&nbsp;</i>Full Name / पूरा नाम :</label>
                                        <div className="col-sm-6">
                                            <input
                                                type="text"
                                                placeholder='FirstName MiddleName LastName'
                                                className="form-control p-2"
                                                id="inputEmail3"
                                                name='name'
                                                value={val.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fas fa-calendar">&nbsp;</i> Aadhaar Number/आधार संख्या </label>
                                        <div className='col-sm-6'>
                                            <input
                                                type="text"
                                                maxLength={12}
                                                title="Please enter exactly 12 digits"
                                                className='form-control p-2'
                                                placeholder="Aadhar Number"
                                                required
                                                name='aadhar'
                                                value={val.aadhar}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row ">
                                        <label className='col-sm-6 col-form-label'> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fa fa-briefcase">&nbsp;</i> Service / सेवा</label>
                                        <div className="col-sm-6">
                                            <select name='service' required onChange={handleChange} value={val.service} className="form-select" id="autoSizingSelect">
                                                <option selected value="">Select Service</option>
                                                <option value="Gazatted">Gazatted</option>
                                                <option value="Non-Gazatted">Non-Gazatted</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fa fa-address-book">&nbsp;</i> Service Cadre / सेवा संवर्ग</label>
                                        <div className='col-sm-6'>
                                            <select required name='service_cadre' className="form-select" id="autoSizingSelect" onChange={handleChange} value={val.service_cadre} >
                                                <option value="" selected>Select Service Cadre </option>
                                                <option value="Indian Administrative Service">Indian Administrative Service</option>
                                                <option value="Indian Police Service">Indian Police Service</option>
                                                <option value="Indian Forest Service">Indian Forest Service</option>
                                                <option value="Bihar Administrative Service">Bihar Administrative Service</option>
                                                <option value="Bihar Police Service">Bihar Police Service</option>
                                                <option value="Bihar Finance Service">Bihar Finance Service</option>
                                                <option value="Bihar Account Service">Bihar Account Service</option>
                                                <option value="Bihar Revenue Service">Bihar Revenue Service</option>
                                                <option value="Bihar Rural Development Service">Bihar Rural Development Service</option>
                                                <option value="Service Of Bihar Engineering">Service Of Bihar Engineering</option>
                                                <option value="Bihar Co-operavtive Account Service">Bihar Co-operavtive Account Service</option>
                                                <option value="Bihar Education Service">Bihar Education Service</option>
                                                <option value="Bihar Health Service">Bihar Health Service</option>
                                                <option value="Bihar Registration Service">Bihar Registration Service</option>
                                                <option value="Bihar Co-operavtive Administrative Service">Bihar Co-operavtive Administrative Service</option>
                                                <option value="Bihar Election Service">Bihar Election Service</option>
                                                <option value="Bihar Agriculture Service">Bihar Agriculture Service</option>
                                                <option value="Bihar Jail Service">Bihar Jail Service</option>
                                                <option value="Bihar Labour Service">Bihar Labour Service</option>
                                                <option value="Bihar Employment Service">Bihar Employment Service</option>
                                                <option value="Bihar ICDS Service">Bihar ICDS Service</option>
                                                <option value="GAD">GAD</option>
                                                <option value="Others">Others</option>
                                            </select>
                                        </div>
                                    </div>



                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'><i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i> <i className="fa fa-envelope">&nbsp;</i>Email / ईमेल</label>
                                        <div className='col-sm-6'>
                                            <input
                                                type="email"
                                                placeholder="Enter Email"
                                                className='form-control p-2'
                                                required
                                                name='email'
                                                value={val.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fas fa-calendar">&nbsp;</i> Date of Birth / जन्म तिथि</label>
                                        <div className='col-sm-6'>
                                            <input
                                                type="date"
                                                placeholder="Date Of Birth"
                                                className='form-control p-2'
                                                required
                                                name='dob'
                                                value={val.dob}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <p align="right"><i style={{ color: 'red', fontSize: '16px' }} s> Please select the date, month & year correctly. Your Date of Birth will be your PASSWORD / कृप्या तिथि, महीना और वर्ष सही तरीके से चुनें। आपकी जन्मतिथि आपका पासवर्ड होगा|</i></p>

                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'>
                                            <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i>
                                            <i className="fa fa-mobile">&nbsp;</i>Mobile No / मोबाइल नं</label>
                                        <div className='col-sm-4'>
                                            <input
                                                type="number"
                                                name='mobile'
                                                value={mobile}
                                                onChange={e => setMobile(e.target.value)}
                                                className="form-control"
                                                id="inputEmail3"
                                                required
                                            />
                                        </div>
                                        <button onClick={handleOtp} className='btn btn-sm btn-primary col-sm-2'>Send OTP</button>
                                    </div>



                                    <p align="right"><i style={{ color: 'red', fontSize: '16px' }} s>Mobile number is important to receive One Time Password (OTP)./वन टाइम पासवर्ड (OTP) प्राप्त करने के लिए मोबाइल नंबर महत्वपूर्ण है।</i>
                                    </p>
                                    <div className="row">
                                        <label className='col-sm-6 col-form-label'> <i className="fa fa-shield">&nbsp;</i> Enter OTP</label>
                                        <div className='col-sm-6'>
                                            <input
                                                type="text"
                                                placeholder="OTP"
                                                required
                                                className='form-control p-2'
                                                name='otp'
                                                value={val.otp}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className='col-sm-4 col-form-label'> <i style={{ color: 'red', fontSize: '18px' }}>*&nbsp;</i><i className="fa fa-shield">&nbsp;</i> Click to Verify </label>
                                        <div className='col-sm-8'>
                                            <ReCAPTCHA
                                                sitekey={"6LcgtoMjAAAAANRHf5wiCV4Z2Bg6kRZs1wSunjBQ"}
                                                onChange={captchaClick}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <br />

                                    {/* <div className="pass"><a href="#">Forgot password?</a></div> */}

                                    <div className="row button mt-5">
                                        {/* <Link to='/login'> */}
                                        <input type="submit"  value="Register" />
                                        {/* </Link> */}
                                    </div>
                                    <div className="signup-link">Already a member? <a href="/login">SignIn now</a></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Register