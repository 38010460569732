import { apiFunction } from "../../apiCall/function"

export const updateApplication = async (data) => {
    return await apiFunction("form/application", "post", data, true, 'body')
}

export const downloadRes = async (aadhar) => {
    return await apiFunction("form/downloadpdf", "post", { aadhar }, true, null)
}

export const downloadCertificate = async () => {
    return await apiFunction("certificate", "get", {}, true, 'body')
}