import { apiFunction } from "../../apiCall/function"

// export const getSlots = async (data) => {
//   return await apiFunction("/form/slots", "get", data, true, 'body')
// }

export const getCenter = async (id) => {
  return await apiFunction("/form/center/" + id, "get", {}, true, 'body')
}

export const getAvailability = async (center, type, month) => {
  let url = `/form/date/${type}/${center}`;
  if (month) {
    url = url + `?month=${month}`
  }
  return await apiFunction(url, "get", {}, true, 'body')
}

export const getSlots = async (center, type, date) => {
  let url = `/form/slots/${type}/${center}/${date}`;
  if (date)
    return await apiFunction(url, "get", {}, true, 'body')
}

export const updateAvailability = async (data) => {
  return await apiFunction(`/form/allotment/`, "post", data, true, 'body')
}

export const initiatePayment = async (amount, id, allotmentId) => {
  let data = {
    amount: amount * 100,
    hdnOrderID: id,
    trnRemarks: "",
    allotmentId: allotmentId,
  }
  return await apiFunction(`form/payment`, "post", data, true, 'body')
}