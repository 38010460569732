import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import { loaderEnd, loaderStart } from '../../components/loader';

import { getQuestion } from './api';
import Question from "./eachQuestion";
import './styles.css'
const limit = 20000;
const QuestionBank = () => {

    const [questions, setQuestions] = useState([])
    const [pageNo, setPageNo] = useState(1)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        getData()
    }, [pageNo])

    const getData = async () => {
        loaderStart()
        let data = {
            "pageNo": (pageNo - 1)*limit,
            "limit": limit
        }
        let res = await getQuestion(data);
        if (res) {
            setQuestions(res.data.data)
            setTotal(res.data.total)
        }
        loaderEnd()
    }

    const handleSelected = (page) => {
        setPageNo(page)
    }

    return (
        <>
            <div className='container-fluid card'>
                <br />
                <h3 className='text-center mb-5'>All Questions</h3>
                {
                    questions.map((q, i) => (
                        <Question question={q} index={(i + 1)+((pageNo-1)*50)} key={i} />
                    ))
                }
                <br />

                <div>
                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={limit}
                        totalItemsCount={total}
                        pageRangeDisplayed={3}
                        onChange={handleSelected}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
            </div>
        </>
    )
}

export default QuestionBank