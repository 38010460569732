export const departments = [
    {
        "key": "Department of Law"
    },
    {
        "key": "Disaster Management"
    },
    {
        "key": "General Administration Department"
    },
    {
        "key": "Home"
    },
    {
        "key": "Information and Public Relations Department"
    },
    {
        "key": "Parliament Affairs"
    },
    {
        "key": "Planning"
    },
    {
        "key": "Vigilance"
    },
    {
        "key": "Office of the Chief Electoral Officer, Bihar"
    },
    {
        "key": "Commercial Taxes"
    },
    {
        "key": "Department of Revenue & Land Reform"
    },
    {
        "key": "Finance"
    },
    {
        "key": "Mines & Geology"
    },
    {
        "key": "Prohibition Excise & Registration Department (Excise)"
    },
    {
        "key": "Prohibition Excise & Registration Department (Registration)"
    },
    {
        "key": "Transport"
    },
    {
        "key": "Education"
    },
    {
        "key": "Health"
    },
    {
        "key": "Department of Science & Technology"
    },
    {
        "key": "Building Construction"
    },
    {
        "key": "Department of Industries"
    },
    {
        "key": "Energy"
    },
    {
        "key": "Information Technology"
    },
    {
        "key": "Public Health Engineering Department"
    },
    {
        "key": "Road Construction"
    },
    {
        "key": "Rural Works"
    },
    {
        "key": "Urban Development and Housing Department"
    },
    {
        "key": "Agriculture"
    },
    {
        "key": "Animal & Fisheries Resources"
    },
    {
        "key": "Co-Operative"
    },
    {
        "key": "Environment & Forest"
    },
    {
        "key": "Food Consumer Protection"
    },
    {
        "key": "Minor Water Resources"
    },
    {
        "key": "Panchayati Raj"
    },
    {
        "key": "Rural Development"
    },
    {
        "key": "Sugarcane"
    },
    {
        "key": "Water Resource"
    },
    {
        "key": "BC & EBC Welfare"
    },
    {
        "key": "Labour Resources"
    },
    {
        "key": "Minority Welfare"
    },
    {
        "key": "SC & ST Welfare"
    },
    {
        "key": "Social Welfare"
    },
    {
        "key": "Tourism"
    },
    {
        "key": "Youth & Art Culture"
    },
    {
        "key": "Others"
    }
]