import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getChapter } from "./api";

import './chapterpage.css'
const ChapterPage = () => {

    const { ...params } = useParams()
    const [data, setData] = useState([])

    useEffect(() => {
        if (params.code) {
            getData()
        }
    }, [])

    const getData = async () => {
        let data = await getChapter(params.code)
        if (data.code) {
            setData(data.data)
        }
    }

    const open = (d) => {
        window.open(process.env.REACT_APP_FILE_URL + d.file, "_blank")
    }

    return (
        <>
            <div className='container-fluid card'>
                <h3 className='text-center mb-5'>Choose Your Chapter</h3>
                <div className='chapter_container'>
                    {
                        data.map((d) => (
                            <div onClick={() => open(d)} className="col-lg-3 col-xs-6 m-1 ">
                                <div className="small-box-chapter bg-teal">
                                    <div className="inner-chapter">
                                        <h4 style={{ fontSize: '20px' }}>
                                            {/* <b>Total Question :</b> {d.total_question} */}
                                        </h4>
                                        <p style={{ height: '70px', fontSize: '20px' }}>{d.name}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa fa-file-pdf"></i>
                                    </div>
                                    <p style={{ textAlign: 'center', display: 'none' }}>
                                        <i className="fa fa-arrow-circle-right"></i>
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default ChapterPage