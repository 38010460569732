import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './subjectpage.css';

const SubjectPage = () => {

    return (
        <>
            <div className='container-fluid card'>
                <h3 className='text-center mb-5'>Subject</h3>
                <div className="col-lg-3 col-xs-6">
                    {/* <a href="question_bank_topic_search.php?lang_code=HINDI&amp;subject_code=CODE001" className="small-box-footer"> */}
                    <Link to='/chapter-page/CODE001'>
                        <div className="small-box bg-teal">
                            <div className="inner">
                                <h4 style={{ fontSize: '20px' }}>
                                    <b>CODE001</b></h4>
                                <p style={{ height: '70px', fontSize: '20px' }}>Computer Competency Test Examination </p>
                            </div>
                            <div className="icon">
                                <i className="fa fa-book"></i>
                            </div>
                            <p style={{ textAlign: 'center', display: 'none' }}>
                                <i className="fa fa-arrow-circle-right"></i>
                            </p>
                        </div>
                    </Link>
                    {/* </a> */}
                </div>
            </div>
        </>
    )
}

export default SubjectPage