import React from "react";
import classes from "./topNav.module.scss";

const TopNav = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.navWrapper} >
        <span className={classes.marqueeSpan} onClick={props.onClick1}>{props.title1}</span>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <span className={classes.marqueeSpan} onClick={props.onClick2}>{props.title2}</span>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <span className={classes.marqueeSpan} onClick={props.onClick2}>{props.title2}</span>
      </div>
    </div>
  );
};

export default TopNav;
