import { apiFunction } from "../../apiCall/function"

export const getAdmitCard = async () => {
  return await apiFunction("form/admit-card", "get", {}, true, 'body')
}


export const getPaymentInfo = async () => {
  return await apiFunction("form/payment-check", "post", {}, true, 'body')
}
